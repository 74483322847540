@use '@/styles/utils/mixins.scss' as *;

.vinSearchWrapper {
  padding-top: 80px;
  padding-bottom: 80px;
  .headingElement {
    position: relative;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-family: 'KiaSignature';
      font-weight: 600;
      font-style: normal;
      &.titleXl {
        font-size: 60px;
        line-height: 1;
        @include tab() {
          font-size: 48px;
          line-height: 1;
        }
        @include mob() {
          font-size: 36px;
          line-height: 1;
        }
      }
      &.titleLg {
        font-size: 48px;
        line-height: 1;
        @include tab() {
          font-size: 36px;
          line-height: 1;
        }
      }
      &.titleMd {
        font-size: 24px;
        line-height: 1;
      }
      &.titleXs {
        font-size: 16px;
        line-height: 1;
      }
    }
  }

  .formLabel {
    font-size: 12px;
    line-height: 1.2;
    color: $kiaMidnightBlack;
    margin-bottom: 24px;
  }
  .formInput {
    border-color: $kiaSilver;
    height: auto;
  }

  .formInput:focus-visible,
  .formInput[data-focus-visible] {
    border-color: $kiaMidnightBlack;
    box-shadow: 0px 1px 0px 0px $kiaMidnightBlack;
  }
  .ctaBtn {
    width: max-content;
    @include mob-tab() {
      width: 100%;
    }
    &:disabled {
      opacity: 0.6;
    }
  }
  .recallBox {
    border: 1px solid $silver;
    padding: 46px 24px;
    font-size: 14px;
    line-height: 1.4;
    @include mob-tab() {
      padding: 24px;
    }
  }
  .recallForm {
    font-size: 14px;
    line-height: 1.4;
  }
  :global {
    .chakra-select__icon-wrapper {
      right: 0;
      background-color: $kiaPolarWhite;
      height: calc(100% + 3px);
    }
  }
}
